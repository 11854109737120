body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .container {
  height: 100vh;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
}

.child {
  scroll-snap-align: center;
} */

@media screen and (max-width: 1025px)  {

  .stackableContainer-parent-mobile {
    margin: 0 auto !important;
    display: block !important;
    width: 100vw !important;
    /* height: 100vh !important; */
    overflow-y: auto;
    overscroll-behavior-y: contain;
    scroll-snap-type: y mandatory;
    align-items: center !important;
    display: flex !important;
    justify-content: space-around !important;
    flex-wrap: wrap !important;
  }
  
  .stackableSubContainer-parent-mobile {
    margin: 0 auto !important;
    display: block !important;
    width: 100vw !important;
    /* height: 100vh !important; */
    overflow-y: auto;
    overscroll-behavior-y: contain;
    scroll-snap-type: y mandatory;
    align-items: center !important;
    display: flex !important;
    justify-content: space-around !important;
    flex-wrap: wrap !important;

  }
  .stackableSubContainer-child-mobile {
    width: 100vw !important; 
    scroll-snap-align: center;
  }
  
  .stackableSubContainer-parent-mint-mobile {
    margin: 0 auto !important;
    display: block !important;
    width: 100vw !important;
    height: 100vh !important;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    scroll-snap-type: y mandatory;
    align-items: center !important;
    display: flex !important;
    justify-content: space-around !important;
    flex-wrap: wrap !important;

  }
  .stackableSubContainer-mint-child-mobile {
    width: 100vw !important; 
    text-align: center !important;
    align-items: center !important;
    scroll-snap-align: center;
  }
  
  .logo-main-mobile {
    width: 40vh !important;
    margin: 0 !important;
  } 

  .bigKnot-mobile {
    display: none !important;
  }

  .controls-mobile {
    margin: 0 !important;
  }

  .nav-mobile {
    margin: 0 !important;
    align-items: center;
    display: flex;
    justify-content: space-around !important;
    top: 15px !important;
  }  
}

@media screen and (max-width: 600px) {
  /* stack the mintbox elements */
  .stackableSubContainer-child-mint-mobile {
    text-align: center !important;
    margin: 0 !important;
    width: 100%;
  }
  .infobox-mobile {
    width: 90vw !important;
  }
}